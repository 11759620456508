#mro_header {
	background-color: $dark_purple;
}

#mro_header_inner {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    padding: 10px 0;

	#mro_menu {}

	#mro_logo {
		flex: 1 1 auto;
		text-align: center;

        a {
            display: block;

            .header_logo_image {
                height: 30px;
                display: block;

                img {
                    height: 100%;
                    vertical-align: top;
                }
            }
        }
	}

	#mro_profile {}
}



#mro_menu_container {
    background-color: $background_purple;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: $white;
    z-index: 99;

    .menu_header {
        display: flex;
        flex-direction: row;
        -webkit-flex-direction: row;
        justify-content: space-between;
        flex-wrap:nowrap;
        -webkit-flex-wrap:nowrap;
        align-items:flex-start;
        -webkit-align-items:flex-start;
        width: 100%;
        min-height:0;


        .mro_menu_title {
            padding: 10px 0; 
            h3 {
                display: block;
                font-size: 1.25rem;
                line-height: 30px;
            }
        }

        .mro_menu_close {
            padding: 10px 0;
        }
    }

    .menu_content {
        >ul {
            margin:0;
            padding:0; 
            list-style: none;
            
            >li {
                border-bottom: 1px solid rgba($white,0.2);
                a {
                    display: block;
                    color: $white;
                    padding: 10px 0;
                    line-height: 20px;
                    @include transition(all .3s ease);

                    &:hover {
                        padding: 10px;
                        background-color:rgba($white,0.1);
                        text-decoration: none; 
                    }
                }
            }
        }
    }
}
/*
<div id="mro_menu_container">
    <div class="mobile_container">  
        <div class="menu_header">
            <div class="mro_menu_title"><h3>Menu</h3></div>
            <div class="mro_menu_close">
                <button type="button" class="btn_normal btn_icon_only btn_inverse_color">
                    <span class="btn_icon"><span class="icon_close"></span></span>
                    <span class="btn_text">Close</span>
                </button>
            </div>
        </div>
        <div class="menu_content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><a href="properties_mro.html">Properties</a></li>
                <li><a href="sdo_mro.html">Smart Division Orders</a></li>
                <li>&nbsp;</li>
                <li><a href="profile.html">Profile</a></li>
                <li><a href="index.html">Log off</a></li>
            </ul>
        </div>
    </div>
</div>
*/