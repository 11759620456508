


#tab_nav {
	padding: 0px 0px 0;
	position: relative;
	background-color: $blue;

	>ul {
		margin:0;
		padding:0;
		list-style: none;
		z-index: 1;
		position: relative;

		&:after {
		    clear: both;
		    content: '';
		    display: block;
		    height: 0;
		    visibility: hidden;
		}


		>li {
			display: block;
			float: left;
		}
	}
}

button.btn_tab {
	@include border-radius(0);
	background-color: transparent;
	position: relative;
	color: $white;
	border-bottom:0px solid $grey;
	padding: 15px;
	line-height: 20px;
	text-transform: inherit;
	font-size: 1.0rem;
	font-weight: 400;



	&:hover, 
	&:focus {
		color: $white;
		background-color: rgba($black,0.1);
	}

	&.btn_tab_active {
		background-color: $white;
		color: $navy;

		&:hover, 
		&:focus {
			background-color: $white;
			color: $navy;
		}

		&:after {
			display: none;
		}
	}
}

.tab_content {
	display: none;
	padding:0px;

	&.tab_content_active {
		display: block;
	}
}