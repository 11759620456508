#alerts_inner {
	position: relative;
}

.alert_container {
	position: relative;
	padding:0px;
	margin-bottom: 20px;
	@include border-bottom-radius(4px);
	margin-top: -20px;
	line-height: 20px;
	background-color: $gradient_purple_1;
	color: $white;

	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:center;
    -webkit-align-items:center;
    width: 100%;
    min-height:0;
    padding: 10px;
    @include box-sizing(border-box);

	&.error {
		background-color: $alert_red;
		color: $white;
	}

	.alert_title {
		margin-right: 20px;
		.alert_icon {
			display: inline-block;
			margin-right: 5px;
			float: left;

			span {
				display: block;
				font-size: 20px;
				line-height: 20px;
			}
		}
	}

	.alert_description {
		flex-grow:1;
	}

	.alert_reason {
		flex-grow:1;
	}

	.alert_data {
		text-align: right;
	}

	.alert_action {
		margin-left: 20px;
	}
}
