#ticker {
	background-color: $darker_grey;
	color: $white;
	padding: 0px 0;
}

#ticker_inner {
	position: relative;

	#ticker_main {
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:space-between;
	    -webkit-align-items:space-between;
	    width: 100%;
	    min-height:0;
	    padding: 0px 0;

	}

	#ticker_toggle {
		text-align: center;
		position: absolute;
		left: 50%;
		bottom:-10px;
		@include translate(-50%,0%);

		button#btn_ticker_toggle {
			color: $white;
			background-color: $darker_grey;
			padding:0 10px;
			@include border-radius(0);
			@include border-bottom-radius(4px);
			line-height: 20px;

			.btn_icon {
				span {
					display: block;
				}
			}

			&.selected {
				.btn_icon {

					span {
						position: relative;
						@include rotate(180);
					}
				}
			}
		}
	}

	#ticker_drawer {
		max-height: 200px;
		overflow: auto;
	}
}


.ticker_container {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:space-between;
    -webkit-align-items:space-between;
    // width: 100%;
    min-height:0;
    padding: 10px 0;
    flex: 1 1 100%;


    &.ticker_split {

    	&:first-child {
	    	flex: 1 1 50%;
	    	padding-right: 20px;
	    	border-right:1px solid rgba($white,0.2);
	    }
		&:last-child {
	    	flex: 1 1 50%;
	    	padding-left: 20px;
		}
	}


	.ticker_data {
		line-height: 20px;
		flex:1 1 auto;
			padding-left:10px;

		&:first-child {
			padding-left: 0;
		}

		&.ticker_last_updated {
			text-align: right;
			white-space: nowrap;
		}

		.change_value {
			display: block;
			line-height: 20px;
			white-space: nowrap;

			&.up {
				color: $ticker_up;

				&:after {
					display: inline-block;
					vertical-align: top;
					line-height: 20px;
					content: $icon_indicator_up;
					font-family: 'pipelinedl';
				}
			}

			&.down {
				color: $ticker_down;

				&:after {
					display: inline-block;
					vertical-align: top;
					line-height: 20px;
					content: $icon_indicator_down;
					font-family: 'pipelinedl';
				}
			}
		}
	}
}



