
.toggle_switch_radio[type="radio"]:checked,
.toggle_switch_radio[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.toggle_switch_radio[type="radio"]:checked + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: block;
    color: $app_color;
    background-color: $white;
    padding: 4px 10px 4px 10px !important;
    text-align: center;
    @include border-radius(20px);
	@include box-sizing(border-box);
	border:1px solid $border_color;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.toggle_switch_radio[type="radio"]:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: block;
    color: rgba($app_color,0.5);
    padding: 4px 10px 4px 10px !important;
    text-align: center;
    @include border-radius(20px);
	@include transition(all .3s ease);
	@include box-sizing(border-box);
	border:1px solid $light_grey;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}


// Hover & Focus States

.toggle_switch_radio[type="radio"]:focus + label,
.toggle_switch_radio[type="radio"]:hover + label {
    color: $app_color;
}


// Disabled State

.toggle_switch_radio[type="radio"]:disabled:checked + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $grey;
    padding: 4px 0 4px 0px;
}

.toggle_switch_radio[type="radio"]:disabled:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: $grey;
    padding: 4px 0 4px 0px;
}








.toggle_switch {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:stretch;
    -webkit-align-items:stretch;
    width: 100%;
    min-height:0;

    background-color:rgba($black,0.05);
    @include border-radius(20px);
	@include box-sizing(border-box);


	.toggle_switch_option {
		flex-grow:1;
	}
}