#quicklinks {}

#quicklinks_inner {
	margin-bottom: 20px;
}


.quicklink_container {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
}

.quicklink_item {
	position: relative;
	padding: 20px;
	@include box-sizing(border-box);
	border:1px solid $app_color;
	@include border-radius(4px);
	background-color: $white;
	margin-right: 20px;
	flex: 1 1 100%;

	&:last-child {
		margin-right:0; 
	}

	.quicklink_icon {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 40px;

		.quicklink_icon_main {
			z-index: 1;
			position: relative;
		}

		.quicklink_icon_shadow {
			position: absolute;
			left: -5px;
			bottom: -5px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}

	.quicklink_title {
		margin-right: 60px;
		font-size: 1.25rem;
		line-height: 140%;
	}

	.quicklink_text {
		margin-right: 60px;
		color: $mid_grey;
		line-height: 20px;

		p {

		}

		a {
			display: inline-block;
		}
	}
	.quicklink_buttons {
		font-size: 0;

		button {
			font-size: $font-size;
			margin-right: 10px;

			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}
