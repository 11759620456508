/* Button Group */

	ul.button_group {
		margin:0;
		padding: 0;
		list-style:none;
		font-size: 0;

		>li {
			display: inline-block;
			font-size: $font_size;
			vertical-align: top;

			&:first-child {
				button {
					@include border-left-radius (4px);
					display: block;
					margin-left: 0;
				}
			}

			&:last-child {
				button {
					@include border-right-radius (4px);
					display: block;
					// border-right: 1px solid $base_color;
				}
			}
		}
	}

	button.btn_group {
		width:auto;
		background-color:transparent;
		position: relative;
	  	color: $app_color;
		line-height:20px;
		margin:0px;
		padding:4px 5px;
		overflow:visible;
		@include border-radius(0px);
		-webkit-appearance:none;
		box-sizing:border-box;
		display: inline-block;
		border:1px solid $base_color;
		// border-right:0px solid;
		cursor:pointer;
		min-width:20px;
		@include box-sizing(border-box);
		position: relative;
		margin-left: -1px;

		&:hover,
		&:focus {
			border-color:$gradient_purple_1;
			color: $gradient_purple_1;
			z-index: 1;
		}

		&:active {
			border-color:$purple;
			color: $white;
			background-color: $purple;
		}

		.btn_icon {
			width: 20px;
			text-align: center;
			display: block;
			padding: 0px;
			vertical-align: top;
			line-height: 30px;

			span {
				line-height: 20px;
				display: block;
			}

			&+.btn_text {
				display: none;
			}
		}


		&.btn_selected {
			border-color: $base_color;
			background-color: $base_color;
			color: $white;

			&:hover,
			&:focus {
				border-color: $base_color;
				background-color: $base_color;
				color: $white;
			}				
		}
	}