.ui-widget {
    font-family: $font_pack;
}


.ui-dialog-titlebar {
    display: none;
}



.ui-dialog {
    border:0px solid !important;
    @include box-shadow(0 14px 24px rgba($black,0.14), 0 2px 6px rgba($black,0.12), 0 2px 15px rgba($black,0.20));
    padding: 0;
    // max-width: 1400px;
    // min-height: 90vh;

    .ui-dialog-content {
        padding: 0;
        // min-height: 120px !important;
    }
}

.ui-widget-overlay {
    background: rgba($navy, 0.3) !important;
    @include opacity(1.0);
}

.ui-widget input, 
.ui-widget select, 
.ui-widget textarea {

} 

.ui-widget button {
    // font-size: 0.76rem;
}


.dialog_header {
    border-bottom: 0px solid $border-color;
    padding:15px 15px 5px;

    .dialog_back {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .dialog_close {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        background-color: $white;
    }


    h1 {
        line-height:20px;
        font-weight: 600;
        text-align: left;
        color: $app_color;
        font-size: 1.25rem;
    }

    h3 {
        line-height:20px;
        font-weight: 400;
        text-align: left;
        color: $app_color;
        font-size: 1.0rem;
        margin-top: 10px;
    }
    
    .dialog_desc {
        text-align: center;
        color: $dark_grey;
        margin: 10px 0;
        padding:0;
    }
}



    

/* Dialog Content */

.dialog_content {
    padding:0px;
    @include box-sizing (border-box);

    &.padding--all {
        padding:15px;
    }

    &.padding--left {
        padding-left:15px;
    }

    &.padding--right {
        padding-right:15px;
    }

    &.padding--top {
        padding-top:15px;
    }

    &.padding--bottom {
        padding-bottom:15px;
    }

    &.padding--vertical {
        padding-top:15px;
        padding-bottom:15px;
    }

    &.padding--horizontal {
        padding-left:15px;
        padding-right:15px;
    }

     .dialog_content_inner {
        padding:15px ;
        overflow:auto;
        position: relative;

        &.no_padding {
            padding: 0;
        }

        @media (max-width: 767px) {
            padding:15px 15px 10px;
        }

        &:after {
            clear: both;
            content: '';
            display: block;
            height: 0;
            visibility: hidden;
        }
    }

    .dialog_inner_content {
        padding: 15px;
        overflow: auto;
        max-height: calc(100vh - 170px);

        &.no_padding {
            padding:0;
        }
    }

    /* Dialog Actions */
    .dialog_actions {
        padding:15px;
        border-top:1px solid $border-color;
        background-color: transparent;


        &.fixed_width {
            min-width: 480px;   
            max-width: 480px;           

            @media (max-width: 767px){
                min-width: 290px;
                max-width: 290px;
            }
            
        }

        .actions_left {
            float: left;

            button, input[type='button'] {
                margin-right: 10px;
                float: left;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            ul {
                margin:0;
                padding:0;
                list-style: none;
                font-size: 0;

                li {
                    display: inline-block;
                    vertical-align: top;
                    font-size: $font_size;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;

                        button, input[type='button'] {
                            margin:0;
                            float: none;
                        }
                    }
                }
            }
        }

        .actions_right {
            float: right;

            button, input[type='button'] {
                margin-left: 10px;
                float: left;

                &:first-of-type {
                    margin-left: 0;
                }
            }

            ul {
                margin:0;
                padding:0;
                list-style: none;
                font-size: 0;

                li {
                    display: inline-block;
                    vertical-align: top;
                    font-size: $font_size;
                    margin-left: 10px;

                    &:first-child {
                        margin-left: 0;

                        button, input[type='button'] {
                            margin:0;
                            float: none;
                        }
                    }
                }
            }
        }


        .actions_center {
            float: none;
            text-align: center;

            button, input[type='button'] {
                margin: 0 5px;
            }
        }
    }
}



/* Dialog Actions */
.dialog_actions {
    padding:15px;
    position: relative;
    background-color: $lighter_grey;
    @include border-bottom-radius(4px);

    // &:before {
    //     content: '';
    //     background-color: rgba($black,0.1);
    //     left: 0;
    //     top: 0;
    //     right: 0;
    //     height: 1px;
    //     position: absolute;
    // }


    &.fixed_width {
        min-width: 480px;   
        max-width: 480px;           

        @media (max-width: 767px){
            min-width: 290px;
            max-width: 290px;
        }
        
    }

    .actions_left {
        float: left;

        button, input[type='button'] {
            margin-right: 10px;
            float: left;

            &:last-of-type {
                margin-right: 0;
            }
        }

        >ul {
            list-style: none;
            margin:0;
            padding:0;
            font-size: 0;
            >li {
                font-size: $font_size;
                display: inline-block;
                vertical-align: top;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                button, input[type='button'] {
                    margin-right: 0px;
                    float: none;
                }
            }
        }
    }

    .actions_right {
        float: right;

        button, input[type='button'] {
            margin-left: 10px;
            float: left;

            &:first-of-type {
                margin-left: 0;
            }
        }

        >ul {
            list-style: none;
            margin:0;
            padding:0;
            font-size: 0;
            >li {
                font-size: $font_size;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }

                button, input[type='button'] {
                    margin-left: 0px;
                    float: none;
                }
            }
        }
    }


    .actions_center {
        float: none;
        text-align: center;

        button, input[type='button'] {
            margin: 0 5px;
        }
    }
}




/* Button Group for Adding Order */

.button_group {
    >ul {
        margin:0;
        padding:0;
        list-style: none;
        float: left;
        width: 100%;

        >li {
            float: left;
            width: calc(100%/3);
            margin:0px 0;
        }
    }
}


.modal_footer_total {
    position: relative;

    .modal_footer_total_value {
        line-height: 30px;
        font-size: 1.4rem;
        font-weight: 600;
        top: 0px;
        position: relative;
    }
}



.dialog_tabs {
    position: relative;
    .dialog_close {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 10px;

        button {
            color: $white;
        }
    }
}



