#pathway {
	background-color: $purple;
	margin-bottom: 20px;
	margin-top: -20px;
}

#pathway_inner {
	padding: 20px 0;
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:space-between;
    -webkit-align-items:space-between;
    width: 100%;
    min-height:0;

	.pathway_left {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;

			>li {
				display: inline-block;
				font-size: $font_size;
				margin-right: 10px;
				vertical-align: middle;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.pathway_right {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;

			>li {
				display: inline-block;
				font-size: $font_size;
				margin-left: 10px;
				vertical-align: middle;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.pathway {
	ul {
		margin:0;
		padding:0;
		list-style: none;
		font-size: 0;
		color: $white;

		li {
			font-size: $font_size;
			display: inline-block;
			vertical-align: top;

			&:after {
				content: '/';
				padding-left: 10px;
				padding-right: 10px;
				display: inline-block;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			a:link,
			a:visited {
				display: inline-block;
				color: $white;
				line-height: 20px;
				padding: 10px 0;

				&:hover {
					text-decoration: underline;
				}
			}

			span {
				color: $white;
				font-size: 1.5rem;
				font-weight: 600;
				display: inline-block;
				line-height: 20px;
				padding: 10px 0;
			}
		}
	}
}