.panel_title {
}

.dropdown_title {
	font-size: 1.25rem; //20px
	line-height: 30px;
}

.search_form_title {
	font-size: 1.25rem; //20px
	line-height: 30px;

	.search_result_count {
		font-size: $font_size;
		font-weight: normal;
	}
}

.minor_title {
	font-size: 0.8rem;
	text-transform: uppercase;
	font-weight: 800;
	line-height: 20px;
	padding:5px 0;
}

.section_title {
	font-size: 1.25rem; //20px
	line-height: 30px;
	font-weight: 900;
}

.module_title {
	font-size: 1.25rem; //20px
	line-height: 30px;
	font-weight: 400;
	margin:20px 0 10px;
}


.mro_title {
	font-size: 1.5rem; //20px
	line-height: 30px;
	font-weight: 800;
	color: $app_color;
	text-align: center;

	@media #{$mobile_portrait} {
	}
}

.intro_text {
		text-align: center;

	p {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	@media #{$mobile_portrait} {
	}
}