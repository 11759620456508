html {
	-moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}


body {
	font-size: $font_size;
	font-family: $font_pack;
	font-weight: 400;
  	color: $app_color;
  	background: $body_background;
  	-webkit-text-size-adjust: none;
  	margin: 0px;
  	padding:0;

  	&.no_scroll {
  		height: 100%;
  		overflow: hidden;
  	}
}

html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}


h1,h2,h3,h4,h5 {
	margin:0px;
	padding:0px;
}

abbr {
	text-decoration: none;
}

a:link, a:visited{
	color:$blue;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	text-decoration: none;

	&.link_arrow_right {
		text-decoration: none;

		&:after {
			content: $icon_arrow_right;
			font-family: 'pipelinedl';
			padding-left: 5px;
		}
	}
}

a:hover {
	color:darken($blue,10%);
	text-decoration: underline;
}


.theme_bank {
	a:link, a:visited{
		color:$purple;
	}

	a:hover {
		color:darken($purple,10%);
	}
}


p {
	margin: 0;
	padding: 0;
	padding-bottom:10px;
	line-height:140%;
}

*:focus {
    outline: none;
}

.clearfix::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
}

.overflow {
	-webkit-overflow-scrolling: touch; /* lets it scroll lazy */
	overflow:auto;
}

.hidden_text {
	position: absolute !important;
	font-size:0 !important;
}

.text_left {
	text-align: left !important;
}

.text_right {
	text-align: right !important;
}

.text_center {
	text-align: center !important;
}

.align_center {
	text-align: center;
}


.space_divider {
	height:10px;
	display: block;

	&.extra_padding {
		margin-top:10px;
		margin-bottom: 10px;
	}
}

.line_divider {
	background-color: rgba($black,0.1);
	margin-top:10px;
	margin-bottom: 10px;
	height:1px;
	display: block;

	&.extra_padding {
		margin-top:30px;
		margin-bottom: 30px;
	}
}


.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


.text_right {
	text-align: right;
}


.fine_print,
.timestamp {
	font-size: 0.8rem;
	line-height: 20px;
	color: $mid_grey;
}


