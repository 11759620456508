.content_action_bar {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    margin-bottom: 10px;

    &.no_margin {
    	margin-bottom: 0;
    }


	.action_bar_left {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			>li {
				display: inline-block;
				font-size: $font_size;
				margin-right: 10px;
				vertical-align: top;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.action_bar_right {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			>li {
				display: inline-block;
				font-size: $font_size;
				margin-left: 10px;
				vertical-align: top;

				&:first-child {
					margin-left: 0;
				}

				.timestamp {
					line-height: 20px;
					padding: 5px 0;
				}
			}
		}
	}
}
