$icomoon-font-family: "pipelinedl" !default;
$icomoon-font-path: "/assets/fonts" !default;   //'/assets/fonts' !default;

$icon_user_network: "\e94f";
$icon_activity_pause: "\e94b";
$icon_activity_success: "\e94c";
$icon_menu: "\e94d";
$icon_mro_profile: "\e94e";
$icon_barrel_shadow: "\e946";
$icon_barrel: "\e947";
$icon_sdo_inner: "\e948";
$icon_sdo_shadow: "\e949";
$icon_sdo: "\e94a";
$icon_documents: "\e944";
$icon_info: "\e945";
$icon_statement: "\e943";
$icon_warning: "\e942";
$icon_print: "\e940";
$icon_three_dots: "\e941";
$icon_operators_shadow: "\e937";
$icon_operators: "\e938";
$icon_client_shake_shadow: "\e92b";
$icon_client_shake: "\e92c";
$icon_commission_shadow: "\e92d";
$icon_commission: "\e92e";
$icon_crude_oil_shadow: "\e92f";
$icon_crude_oil: "\e930";
$icon_flame_shadow: "\e931";
$icon_flame: "\e932";
$icon_helpdesk_shadow: "\e933";
$icon_helpdesk: "\e934";
$icon_new_payments_shadow: "\e935";
$icon_new_payments: "\e936";
$icon_overdue_monitor_shadow: "\e939";
$icon_overdue_monitor: "\e93a";
$icon_payment_change_shadow: "\e93b";
$icon_payment_change: "\e93c";
$icon_profile: "\e93d";
$icon_wells_shadow: "\e93e";
$icon_wells: "\e93f";
$icon_filter: "\e924";
$icon_invite: "\e925";
$icon_marker: "\e926";
$icon_recent: "\e927";
$icon_well_live: "\e928";
$icon_well_production: "\e929";
$icon_well_shut: "\e92a";
$icon_loading: "\e923";
$icon_back: "\e922";
$icon_trash: "\e921";
$icon_add: "\e91f";
$icon_minus: "\e920";
$icon_arrow_left: "\e918";
$icon_arrow_right: "\e919";
$icon_chart: "\e91a";
$icon_gas_shadow: "\e91b";
$icon_gas: "\e91c";
$icon_share: "\e91d";
$icon_table: "\e91e";
$icon_create: "\e913";
$icon_production_shadow: "\e914";
$icon_production: "\e915";
$icon_sales_shadow: "\e916";
$icon_sales: "\e917";
$icon_payment_shadow: "\e911";
$icon_payment: "\e912";
$icon_error: "\e90c";
$icon_owner_shadow: "\e90d";
$icon_owner: "\e90e";
$icon_property_shadow: "\e90f";
$icon_property: "\e910";
$icon_blockchain_shadow: "\e900";
$icon_blockchain: "\e901";
$icon_chevron_down: "\e902";
$icon_chevron_left: "\e903";
$icon_chevron_right: "\e904";
$icon_chevron_up: "\e905";
$icon_close: "\e906";
$icon_indicator_down: "\e907";
$icon_indicator_up: "\e908";
$icon_notification: "\e909";
$icon_search: "\e90a";
$icon_upload_cloud: "\e90b";

