#mro_payments,
#mro_recent_activity,
#mro_performance,
#mro_markets,
#mro_sdo,
#mro_properties,
#mro_content {
	background-color: $white;
	padding-top: 20px;
}


.mro_content_inner {
	padding: 20px 0;

	p {
		&:last-child {
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}



#period_filter {
	margin:10px 10%;
}


.main_icon_container {
	position: relative;
	right: 0;
	top: 0;
	font-size: 40px;
	width: 40px;
	margin:0px auto 10px;
	
	.main_icon {
		z-index: 2;
		position: relative;
	}

	.main_icon_inner {
		z-index: 1;
		position: absolute;
		left: 0px;
		bottom: 0px;
		color: $white;
	}

	.main_icon_shadow {
		position: absolute;
		left: -5px;
		bottom: -5px;
		z-index: 0;
		color: $purple_blush;
	}
}



.mro_header_icon_container {
	position: relative;
	right: 0;
	top: 0;
	font-size: 60px;
	width: 60px;
	margin:0px auto 20px;
	
	.mro_header_icon {
		z-index: 2;
		position: relative;
	}

	.mro_header_icon_inner {
		z-index: 1;
		position: absolute;
		left: 0px;
		bottom: 0px;
		color: $white;
	}

	.mro_header_icon_shadow {
		position: absolute;
		left: -5px;
		bottom: -5px;
		z-index: 0;
		color: $purple_blush;
	}
}




// Actions List

.mro_actions {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;

	&.full {
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;

		.mro_actions_left {
			flex: 1 1 100%;
			text-align: center;

			>ul {
				>li {
					display: block;
					margin:0;
					margin-bottom: 10px;
				}
			}
		}
		.mro_actions_right {
			flex: 1 1 100%;
			text-align: center;

			>ul {
				>li {
					display: block;
					margin:0;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.mro_actions_left {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			
			>li {
				display: inline-block;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.mro_actions_right {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			
			>li {
				display: inline-block;
				margin-left: 10px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}


