.page_container {
    max-width: 1140px;
    margin:0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.message_container {
    max-width: 1170px;
    margin:0 auto;
    padding-left: 5px;
    padding-right: 5px;
}

.mobile_container {
    max-width: 960px;
    margin:0 auto;
    padding-left: 10px;
    padding-right: 10px;
}
