.btn_drawer {
    border: none;
    display: block;
    cursor: pointer;
    position: relative;
    line-height: 20px;
    padding: 10px 10px 10px 30px;
    width: 100%;
    text-align: left;
    @include border-radius(4px);
    background-color:transparent;
    color: $app_color;
    margin:0px;
    overflow:visible;
    -webkit-appearance:none;
    box-sizing:border-box;
    border:0px solid;
    @include box-sizing(border-box);

    .btn_icon {
        @include transition(all .1s ease);
        position: absolute;
        left: 5px;
        top: 10px;

        span {
            display: block;
            line-height: 20px;
            width: 20px;
            text-align: center;
        }
    }



    &:hover, &:focus {
        color: $purple;
    }

    &:hover {
        background-color: $white;
    }


    &.btn_drawer_active {
        color: $app_color;
    
    	.btn_icon {
    		@include rotate(90);
    	}
    }
}


.drawer_container {
    padding: 10px 0;
    border-bottom: 1px solid $purple_blush;

    &:first-of-type {
        border-top: 1px solid $purple_blush;
    }

    .drawer_header {
        position: relative;

        .drawer_header_toggle {
            position: absolute;
            left: 0;
            top: 0;
        }

        .drawer_header_title {

            .drawer_content {
                margin-left: 30px;
                
                .tree_link {
                    width: 100%;
                    display: block;
                    @include box-sizing (border-box);

                }
            }

            a {
                font-weight: 600;
            }
        }
    }

    /* Drawer Content (Hidden by Default) */
    .drawer_content {
        padding: 0px 10px 10px 30px;
        display: none;
        position: relative;

        &.drawer_active {
            display:block;
        }
    }

}


