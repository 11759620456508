#header {
    border-bottom: 1px solid $border_color;
    background-color: $white;
}

#header_inner {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    padding: 10px 0;


    #header_logo {
        flex: 1 1 auto;

        a {
            display: inline-block;

            .header_logo_image {
                height: 40px;
                display: block;

                img {
                    height: 100%;
                    vertical-align: top;
                }
            }
        }
    }

    #header_nav {
        ul {
            margin:0;
            padding:0;
            list-style:none;
            font-size: 0;

            li {
                display: inline-block;
                font-size: $font_size;
                
                a {
                    display: block;
                    text-decoration: none;
                    line-height: 20px;
                    padding:10px;
                    color: $app_color;

                    &:hover, 
                    &:focus {
                        color: $black;
                    }
                }
            }
        }
    }

    #header_notifications {
        margin-left: 20px;

        button.btn_normal {

            .btn_icon {
                &.has_notifications {
                    position: relative;

                    &:after {
                        content: '';
                        height: 12px;
                        width: 12px;
                        background-color: $red;
                        display: block;
                        position: absolute;
                        right: -4px;
                        top: -4px;
                        border:2px solid $white;
                        @include border-radius(10px);
                        @include box-sizing(border-box);
                    }
                }
            }
        }
    }

    #header_search {}

    #header_user {
        margin-left: 20px;
    }
}



/* Bank Specific Theme */

.theme_bank {
    #header {
        border-bottom: 0px solid $border_color;
        background-color: $dark_purple;
        @include linear-gradient(90deg, $light_purple 0%, $dark_purple 100%);

        #header_inner {
            #header_nav {
                >ul {
                    >li {
                        a {
                            color: $white;

                            &:hover, 
                            &:focus {
                                color: darken($white,10%);
                            }
                        }
                    }
                }
            }

            #header_notifications {

                button.btn_normal {

                    .btn_icon {
                        &.has_notifications {

                            &:after {
                                background-color: $yellow;
                                border:2px solid $dark_purple;
                            }
                        }
                    }
                }
            }
        }
    }
}