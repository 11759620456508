#footer {
}

#footer_inner {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    align-items:space-between;
    -webkit-align-items:space-between;
    width: 100%;
    min-height:0;
    padding: 30px 0;
    color: $mid_grey;


	#footer_legal {
		line-height: 20px;
	}

	#footer_links {
		ul {
            margin:0;
            padding:0;
            list-style:none;
            font-size: 0;

            li {
                display: inline-block;
                font-size: $font_size;
                
                a {
                    display: block;
                    text-decoration: none;
                    line-height: 20px;
                    padding:0px 10px;
                    color: $mid_grey;

                    &:hover, 
                    &:focus {
                        color: $app_color;
                    }
                }
            }
        }
	}

	#footer_logo {
		flex:1 1 100%;
		height: 40px;
		margin-top: 20px;

		img {
			height: 100%;
		}
	}


}