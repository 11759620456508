@mixin transition($args...) {
  	-webkit-transition: $args;
  	-moz-transition: $args;
  	-ms-transition: $args;
  	-o-transition: $args;
  	transition: $args;
}


@mixin border-radius($radius) {
  	-webkit-border-radius: $radius;
  	border-radius: $radius;
  	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// Single side border-radius
@mixin border-top-radius($radius) {
  	-webkit-border-top-right-radius: $radius;
  	border-top-right-radius: $radius;
   	-webkit-border-top-left-radius: $radius;
   	border-top-left-radius: $radius;
   	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  	-webkit-border-bottom-right-radius: $radius;
  	border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
 	-webkit-border-bottom-right-radius: $radius;
  	border-bottom-right-radius: $radius;
   	-webkit-border-bottom-left-radius: $radius;
   	border-bottom-left-radius: $radius;
   	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  	-webkit-border-bottom-left-radius: $radius;
  	border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-radius-topleft: $radius;
    border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topright: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-bottom-left-radius: $radius;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


// @mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
//   -webkit-box-shadow: $horizontal $vertical $blur $color;
//   -moz-box-shadow: $horizontal $vertical $blur $color;
//   box-shadow: $horizontal $vertical $blur $color;
// }

@mixin box-shadow($params...) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}


@mixin inset-box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: inset $horizontal $vertical $blur $color;
  -moz-box-shadow: inset $horizontal $vertical $blur $color;
  box-shadow: inset $horizontal $vertical $blur $color;
}


//box sizing mixin
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

//box shadow
// @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
//   @if $inset {
//     -webkit-box-shadow:inset $top $left $blur $color;
//     -moz-box-shadow:inset $top $left $blur $color;
//     box-shadow:inset $top $left $blur $color;
//   } @else {
//     -webkit-box-shadow: $top $left $blur $color;
//     -moz-box-shadow: $top $left $blur $color;
//     box-shadow: $top $left $blur $color;
//   }
// }

//white panel
@mixin panel() {
  background-color: $white;
  @include border-radius(5px);
  margin-bottom: 10px;
}





// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}


@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}



/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
