// @import 'assets/styles/gc.spread.sheets.excel2013white.css';
// /* You can add global styles to this file, and also import other style files */
// @import 'assets/styles/fonts/source-sans-pro.css';

@import "assets/styles/base";
@import "assets/styles/base/all";
@import "assets/styles/base/icons/style";
@import "assets/styles/base/icons/fonts/source-sans-pro.css";
@import "assets/styles/spread/gc.spread.sheets.excel2013white.css";

// html,
// body {
//   height: 100%;
//   width: 100%;
// }

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  left: 45%;
  margin: -25px 0 0 -25px;
  width: 200px;
  height: 300px;

  & .path {
    stroke:#90beef
    ;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
