.chart_toolbar {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:no-wrap;
    -webkit-flex-wrap:no-wrap;
    align-items:space-between;
    -webkit-align-items:space-between;
    width: 100%;
    min-height:0;
    margin-bottom: 20px;


	.chart_toolbar_left {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size:0;

			>li {
				display: inline-block;
				font-size: $font-size;
				margin-right: 10px;
		vertical-align: top;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.chart_toolbar_right {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size:0;

			>li {
				display: inline-block;
				font-size: $font-size;
				margin-left: 10px;
		vertical-align: top;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}


.chart_container {
	.chart_placeholder {
		height: 200px;
		background-color: $lighter_grey;
	}
}


/* Chart Period Switcher */

.period_dates {
	font-size: 0;

	.period_date_prev {
		display: inline-block;
		font-size: $font-size;

		button {
			&[disabled] {
				background-color: transparent !important;
			}
		}
	}
	.period_date_current {
		display: inline-block;
		font-size: $font-size;
		line-height: 30px;
		vertical-align: top;

		#period_current_date_start {
			display: inline-block;
		}
		#period_current_date_end {
			display: inline-block;
		}
	}
	.period_date_next {
		display: inline-block;
		font-size: $font-size;

		button {
			&[disabled] {
				background-color: transparent !important;
			}
		}
	}
}


/* Pie Chart */

.chart_donut_container {
	position: relative;

	#chart_donut {
		z-index: 2;
		position: relative;
	}

	.chart_donut_value {
		position: absolute;
		left: 50%;
		top: 50%;
		@include translate(-50%, -50%);
		z-index: 1;
		line-height: 40px;
		font-size: 1.65rem;
	    font-weight: 500;
	    line-height: 40px;
	}
}



