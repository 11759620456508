@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9kuqa3') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9kuqa3') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9kuqa3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_"], [class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_user_network {
  &:before {
    content: $icon_user_network; 
  }
}
.icon_activity_pause {
  &:before {
    content: $icon_activity_pause; 
  }
}
.icon_activity_success {
  &:before {
    content: $icon_activity_success; 
  }
}
.icon_menu {
  &:before {
    content: $icon_menu; 
  }
}
.icon_mro_profile {
  &:before {
    content: $icon_mro_profile; 
  }
}
.icon_barrel_shadow {
  &:before {
    content: $icon_barrel_shadow; 
  }
}
.icon_barrel {
  &:before {
    content: $icon_barrel; 
  }
}
.icon_sdo_inner {
  &:before {
    content: $icon_sdo_inner; 
  }
}
.icon_sdo_shadow {
  &:before {
    content: $icon_sdo_shadow; 
  }
}
.icon_sdo {
  &:before {
    content: $icon_sdo; 
  }
}
.icon_documents {
  &:before {
    content: $icon_documents; 
  }
}
.icon_info {
  &:before {
    content: $icon_info; 
  }
}
.icon_statement {
  &:before {
    content: $icon_statement; 
  }
}
.icon_warning {
  &:before {
    content: $icon_warning; 
  }
}
.icon_print {
  &:before {
    content: $icon_print; 
  }
}
.icon_three_dots {
  &:before {
    content: $icon_three_dots; 
  }
}
.icon_operators_shadow {
  &:before {
    content: $icon_operators_shadow; 
  }
}
.icon_operators {
  &:before {
    content: $icon_operators; 
  }
}
.icon_client_shake_shadow {
  &:before {
    content: $icon_client_shake_shadow; 
  }
}
.icon_client_shake {
  &:before {
    content: $icon_client_shake; 
  }
}
.icon_commission_shadow {
  &:before {
    content: $icon_commission_shadow; 
  }
}
.icon_commission {
  &:before {
    content: $icon_commission; 
  }
}
.icon_crude_oil_shadow {
  &:before {
    content: $icon_crude_oil_shadow; 
  }
}
.icon_crude_oil {
  &:before {
    content: $icon_crude_oil; 
  }
}
.icon_flame_shadow {
  &:before {
    content: $icon_flame_shadow; 
  }
}
.icon_flame {
  &:before {
    content: $icon_flame; 
  }
}
.icon_helpdesk_shadow {
  &:before {
    content: $icon_helpdesk_shadow; 
  }
}
.icon_helpdesk {
  &:before {
    content: $icon_helpdesk; 
  }
}
.icon_new_payments_shadow {
  &:before {
    content: $icon_new_payments_shadow; 
  }
}
.icon_new_payments {
  &:before {
    content: $icon_new_payments; 
  }
}
.icon_overdue_monitor_shadow {
  &:before {
    content: $icon_overdue_monitor_shadow; 
  }
}
.icon_overdue_monitor {
  &:before {
    content: $icon_overdue_monitor; 
  }
}
.icon_payment_change_shadow {
  &:before {
    content: $icon_payment_change_shadow; 
  }
}
.icon_payment_change {
  &:before {
    content: $icon_payment_change; 
  }
}
.icon_profile {
  &:before {
    content: $icon_profile; 
  }
}
.icon_wells_shadow {
  &:before {
    content: $icon_wells_shadow; 
  }
}
.icon_wells {
  &:before {
    content: $icon_wells; 
  }
}
.icon_filter {
  &:before {
    content: $icon_filter; 
  }
}
.icon_invite {
  &:before {
    content: $icon_invite; 
  }
}
.icon_marker {
  &:before {
    content: $icon_marker; 
  }
}
.icon_recent {
  &:before {
    content: $icon_recent; 
  }
}
.icon_well_live {
  &:before {
    content: $icon_well_live; 
  }
}
.icon_well_production {
  &:before {
    content: $icon_well_production; 
  }
}
.icon_well_shut {
  &:before {
    content: $icon_well_shut; 
  }
}
.icon_loading {
  &:before {
    content: $icon_loading; 
  }
}
.icon_back {
  &:before {
    content: $icon_back; 
  }
}
.icon_trash {
  &:before {
    content: $icon_trash; 
  }
}
.icon_add {
  &:before {
    content: $icon_add; 
  }
}
.icon_minus {
  &:before {
    content: $icon_minus; 
  }
}
.icon_arrow_left {
  &:before {
    content: $icon_arrow_left; 
  }
}
.icon_arrow_right {
  &:before {
    content: $icon_arrow_right; 
  }
}
.icon_chart {
  &:before {
    content: $icon_chart; 
  }
}
.icon_gas_shadow {
  &:before {
    content: $icon_gas_shadow; 
  }
}
.icon_gas {
  &:before {
    content: $icon_gas; 
  }
}
.icon_share {
  &:before {
    content: $icon_share; 
  }
}
.icon_table {
  &:before {
    content: $icon_table; 
  }
}
.icon_create {
  &:before {
    content: $icon_create; 
  }
}
.icon_production_shadow {
  &:before {
    content: $icon_production_shadow; 
  }
}
.icon_production {
  &:before {
    content: $icon_production; 
  }
}
.icon_sales_shadow {
  &:before {
    content: $icon_sales_shadow; 
  }
}
.icon_sales {
  &:before {
    content: $icon_sales; 
  }
}
.icon_payment_shadow {
  &:before {
    content: $icon_payment_shadow; 
  }
}
.icon_payment {
  &:before {
    content: $icon_payment; 
  }
}
.icon_error {
  &:before {
    content: $icon_error; 
  }
}
.icon_owner_shadow {
  &:before {
    content: $icon_owner_shadow; 
  }
}
.icon_owner {
  &:before {
    content: $icon_owner; 
  }
}
.icon_property_shadow {
  &:before {
    content: $icon_property_shadow; 
  }
}
.icon_property {
  &:before {
    content: $icon_property; 
  }
}
.icon_blockchain_shadow {
  &:before {
    content: $icon_blockchain_shadow; 
  }
}
.icon_blockchain {
  &:before {
    content: $icon_blockchain; 
  }
}
.icon_chevron_down {
  &:before {
    content: $icon_chevron_down; 
  }
}
.icon_chevron_left {
  &:before {
    content: $icon_chevron_left; 
  }
}
.icon_chevron_right {
  &:before {
    content: $icon_chevron_right; 
  }
}
.icon_chevron_up {
  &:before {
    content: $icon_chevron_up; 
  }
}
.icon_close {
  &:before {
    content: $icon_close; 
  }
}
.icon_indicator_down {
  &:before {
    content: $icon_indicator_down; 
  }
}
.icon_indicator_up {
  &:before {
    content: $icon_indicator_up; 
  }
}
.icon_notification {
  &:before {
    content: $icon_notification; 
  }
}
.icon_search {
  &:before {
    content: $icon_search; 
  }
}
.icon_upload_cloud {
  &:before {
    content: $icon_upload_cloud; 
  }
}

