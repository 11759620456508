.inner_nav {
	margin-bottom: 10px;
	// text-align:right;
	// background-color: $light_grey;
	margin:-20px -20px 0;
	padding: 20px;
	@include border-top-radius(4px);
	border-bottom: 1px solid $border_color;
	>ul {
		margin:0;
		padding:0;
		list-style: none;
		font-size: 0;
		>li {
			display: inline-block;
			font-size: $font_size;
			margin-right: 20px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}