
/* Item Header - Property/Owner/Production */
.item_header {
	display: flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	justify-content: space-between;
	flex-wrap:nowrap;
	-webkit-flex-wrap:nowrap;
	align-items:flex-start;
	-webkit-align-items:flex-start;
	width: 100%;
	min-height:0;
	background-color: $lighter_grey;
	padding: 20px;
	@include box-sizing(border-box);
	@include border-radius(4px);

	.item_icon {
		position: relative;
		padding:0px;
		font-size: 40px;
		margin-right: 20px;

		.item_icon_main {
			margin-left: 5px;
			z-index: 1;
			position: relative;
		}

		.item_icon_shadow {
			position: absolute;
			left:0px;
			top:5px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}

	.item_details {
		flex: 1 1 100%; 

		display: flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		justify-content: space-between;
		flex-wrap:wrap;
		-webkit-flex-wrap:wrap;
		align-items:flex-start;
		-webkit-align-items:flex-start;
		width: 100%;
		min-height:0;
		background-color: $lighter_grey;
		@include box-sizing(border-box);
		@include border-radius(4px);

		#item_name {
			line-height: 20px;
			color: $mid_grey;
			font-size: 1.00rem;
			font-weight: 600;

			&:after {
				content: ' -';
			}
		}

		#item_number {
			flex-grow: 1;
			line-height: 20px;
			padding-left: 5px;
			color: $mid_grey;
			font-weight: 600;
			font-size: 1.00rem;
		}

		#item_blockchain {
			line-height: 20px;

			a {
				color: inherit;
			}
		}

		#item_address {
			flex: 1 1 100%; 
			font-size: 1.25rem;
			line-height: 30px;
			font-weight: 600;
			color: $dark_grey;
		}
	}
}



/* Royalty Information */

.royalty_container {
	display: flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	justify-content: space-between;
	flex-wrap:nowrap;
	-webkit-flex-wrap:nowrap;
	align-items:stretch;
	-webkit-align-items:stretch;
	width: 100%;
	min-height:0;
	@include box-sizing(border-box);
	@include border-radius(4px);


	.royalty_owner {
		padding:15px;
		@include box-sizing(border-box);
		flex:1 1 320px;
		background-color: $lighter_grey;
		@include border-left-radius(4px);
		position: relative;

		&:after {
			content: '';
			position: absolute;
			right: 0;
			top:0;
			bottom: 0;
			width: 3px;
			background-color: rgba($black,0.05);
		}

		.royalty_owner_details {
			position: relative;
			margin-bottom: 20px;

			.royalty_owner_icon {
				position: absolute;
				left: 0;
				top: 0;

				span {
					display: block;
					line-height: 40px;
					width: 40px;
					padding:0px;
					font-size: 40px;
					text-align: center;
					@include border-radius(80px);
					background-color: $white;
					color: $mid_grey;
					@include box-sizing(border-box);
				}
			}
			.royalty_owner_name {
				margin-left: 50px;
				display: block;
		        line-height:20px;
		        font-weight: 600;
		        text-align: left;
		        color: $app_color;
		        font-size: 1.25rem;
			}
			.royalty_owner_title {
				margin-left: 50px;
				display: block;
		        line-height:20px;
		        font-weight: 400;
		        text-align: left;
		        color: $mid_grey;
		        font-size: $font_size;
			}
		}
	}

	.royalty_details {
		@include box-sizing(border-box);
		flex: 1 1 100%;
	}
}




.royalty_status {
    line-height: 20px;
    padding: 5px;

    &.pending {
        .royalty_status_icon {
            color: $yellow;
            font-size: 20px;
            line-height: 20px;
            margin-right: 5px;
            display: inline-block;
            vertical-align: top;
        }
    }
}

