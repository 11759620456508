// Payments

ul.payments_list {
	margin:0;
	padding:0;
	list-style: none;
	margin-bottom: 20px;

	>li {
		border-bottom: 1px solid $purple_blush;
		.payments_container {
			a.payments_link {
				display: block;
				color: inherit;
				text-decoration: none;


				display: flex;
			    flex-direction: row;
			    -webkit-flex-direction: row;
			    justify-content: space-between;
			    flex-wrap:wrap;
			    -webkit-flex-wrap:wrap;
			    align-items:flex-start;
			    -webkit-align-items:flex-start;
			    width: 100%;
			    min-height:0;
			    line-height: 20px;
			    padding:10px 0;


				&:hover, &:link {

				}

				.paymnent_date {
					flex:1 1 100%; 
				}

				.payment_description {
					flex:0 1 50%; 
					font-weight: 600;
					font-size: 1.00rem;
				}

				.payment_value {
					flex:0 1 50%;
					font-weight: 600;
					text-align: right;
					color: $purple;
				}
			}
		}
	}
}



// Recent Activity 

.site_lists {

	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    // max-width: 320px;
    min-height:0;
    line-height: 20px;
    padding:10px 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

	.site_item {
		text-align: center;
		padding:10px;
		@include box-sizing(border-box);
		@include border-radius(5px);
		background-color: $lighter_grey;
		min-width: 160px;
		margin-left: 10px;
		margin-right:10px;

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		.site_name {
			h3 {
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: $font_size;
				font-weight: 400;
			}
		}
		.site_number {
			color: $mid_grey;
		}

		.site_icon {
			position: relative;
			right: 0;
			top: 0;
			font-size: 60px;
			width: 60px;
			margin:10px auto 20px;
			
			.site_icon_main {
				z-index: 1;
				position: relative;
			}

			.site_icon_shadow {
				position: absolute;
				left: -5px;
				bottom: -5px;
				z-index: 0;
				color: $purple_blush;
			}

			.site_status {
				position: absolute;
				right: 0;
				bottom: 0;
				font-size: 20px;
				font-family: "pipelinedl";
				z-index: 2;
				background-color: $white;
				@include border-radius(20px);

				&.active {
					&:after {
						content: $icon_activity_success;
						color: $green;
					}
				}

				&.paused {
					&:after {
						content: $icon_activity_pause;
						color: $dark_grey;
					}
				}

				&.inactive {
					&:after {
						content: $icon_warning;
						color: $red;
					}
				}
			}
		}

		.site_value {
			font-size: 1.0rem;
			font-weight: 600;
		}

		.site_production {}

		.site_reference {
			margin-top: 10px;
			font-size: 0.9rem;
			color: $mid_grey;
		}
	}
}



// Performance

.info_component {
	padding:15px;
	border:1px solid $purple_blush;
	@include border-radius(4px);

	&.no_border {
		border:0px solid $purple_blush;
	}
}

.production_row {
	margin: 0px 0 20px;

	.production_label {
		text-align: center;
		line-height: 140%;
		display: block;
	}

	.production_value {
		text-align: center;
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 140%;

		small {
			display: block;
			font-size: 0.8rem;
			line-height: 20px;
			font-weight: 400;
		}
	}
}



// Smart Division Order

.sdo_header {
	padding:10px;
	@include border-radius(4px);
	background-color: $lighter_grey;
	text-align: center;
	margin-bottom: 20px;

	.sdo_operator {
		display: block;
		line-height: 30px;
	}

	.sdo_bc_d {
		display: block;
		line-height: 20px;

		a {
			color: inherit;
		}
	}
}




// Property Details

.property_header {
	margin-bottom:10px;

	.property_header_inner {
		background-color: $lighter_grey;
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: flex-start;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:center;
	    -webkit-align-items:center;
	    width: 100%;
	    @include box-sizing(border-box);
	    padding:10px;

		.property_header_icon {
			margin-right: 10px;

			.main_icon_container {
				margin-bottom: 0;
			}
		}

		.property_header_name {
			margin-right: auto;
			font-size: 1.00rem;
		}

		.property_header_toggle {}
	}
}


// Property Statement Header


.mro_stmnt_header {
	background-color: $toolbar_grey;;
	color: $white;

	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;
    padding: 10px;
    @include box-sizing(border-box);
    @include border-top-radius(5px);
    
    .mro_stmnt_prev {
    	flex: 1 1 30px;
    }
	.mro_stmnt_title {
		line-height: 30px;
		flex: 1 1 calc(100% - 60px);
		text-align: center;
		margin-bottom: 10px;
	}
	.mro_stmnt_next {
    	flex: 1 1 30px;
    	text-align: right;
    }

	.mro_stmnt_date {
		flex-grow: 1;
		line-height: 20px;
		text-align: center;
	}

	.mro_stmnt_data {
		line-height: 20px;

		a.blockchain_explorer_icon {
			color: $white;
			display: block;
			font-size: 20px;
			text-decoration: none;
			height: 20px;
			margin-right: 10px;

			span {
				display: block;
			}

			&:hover, &:focus {
				text-decoration: none;
				color: $gradient_purple_1;
			}
		}
	}
}



.stmnt_footer_paid {
	color: $white;
	background-color: $toolbar_grey;
	display: block;
	@include border-radius(5px);
	padding:20px;
	margin:20px 0;

	.data_row {
		.data_label {
			color: $white;
		}
	}
}