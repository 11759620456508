.toggle_group {
	flex-grow: 1;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:wrap;
    -webkit-flex-wrap:wrap;
    align-items:stretch;
    -webkit-align-items:stretch;
    width: 100%;
    min-height:0;
    text-align: left;
    @include box-sizing(border-box);
    background-color: $lighter_grey;
    @include border-radius(4px);

    .toggle_group_option {
	    flex: 1 1 auto;
	    @include box-sizing(border-box);
	    position: relative;


	    &:first-child {
	    	label {
	    		@include border-left-radius(4px);
	    	}
	    }

	    &:last-child {
	    	label {
	    		@include border-right-radius(4px);
	    	}
	    }

	    .toggle_input {
	    	position: absolute;
	    	opacity: 0;
	    	font-size: 0;
	    	z-index: -1;
	    }

	    label {
	    	display: block;
	    	padding: 4px 9px;
	    	text-align: center;
	    	cursor: pointer;
	    	border:1px solid transparent;
	    	line-height: 20px;
	    	@include box-sizing(border-box);
	    	position: relative;
	    	min-width: 40px;

	    	span {
	    		display: block;
	    		line-height: 20px;
	    	}

	    	&.toggle_active {
	    		border-color: $blue;
	    		background-color: $white;
	    		color: $blue;
	    		@include border-radius(4px);
	    	}
	    }
    }
}


.theme_bank {
	.toggle_group {
		.toggle_group_option {
			label {
		    	padding:5px 10px;
				border:0px solid;


				&.toggle_active {
					border:0px solid;
		    		@include linear-gradient(90deg, $gradient_purple_1 0%, $gradient_purple_2 100%);
		    		color: $white;
		    		padding:5px 10px;
		    	}
			}
		}
	}
}