input, button, textarea {
	font-size:$font_size;
	font-family: $font_pack !important;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}


.btn_normal {
	width:auto;
	background-color:transparent;
	position: relative;
  	color: $app_color;
	line-height:20px;
	margin:0px;
	padding:5px 10px;
	overflow:visible;
	@include border-radius(4px);
	-webkit-appearance:none;
	box-sizing:border-box;
	display: inline-block;
	border:0px solid;
	cursor:pointer;
	// @include transition(all .3s ease);
	min-width:20px;
	@include box-sizing(border-box);
	font-size: $font_size;

	&.btn_inverse_color {
		color: $white;

		&:hover,
		&:focus {
			// @include box-shadow(0px 0px 1px rgba($black,0.5));
			color: darken($white,10%);
		}
	}

	&:hover,
	&:focus {
		// @include box-shadow(0px 0px 1px rgba($black,0.5));
		color: $black;
	}

	&:active {
	}

	&:disabled {
		color: $grey !important;
		cursor: default !important;
		background-color: rgba($black,0.05) !important;
		border: 0px solid !important;
		padding: 5px 10px;

		&:hover,
		&:focus {
			border-color: rgba($black,0.05) !important;
			background-color: rgba($black,0.05) !important;
			color: $grey !important;
		}
	}


	&.btn_purple {
		@include linear-gradient(90deg, $gradient_purple_1 0%, $gradient_purple_2 100%);
		color: $white;

		&:focus,
		&:hover {
		@include linear-gradient(90deg, darken($gradient_purple_1,5%) 0%, darken($gradient_purple_2,5%) 100%);
			color: $white;
		}

		&:disabled {
			color: $grey !important;
			cursor: default !important;
			background-color: rgba($black,0.05) !important;

			&:hover,
			&:focus {
				background-color: rgba($black,0.05) !important;
				color: $grey !important;
			}
		}
	}


	&.btn_blue {
		background-color: $blue;
		color: $white;

		&:focus,
		&:hover {
			background-color: darken($blue,0.1);
			color: $white;
		}

		&:disabled {
			color: $grey !important;
			cursor: default !important;
			background-color: rgba($black,0.05) !important;

			&:hover,
			&:focus {
				background-color: rgba($black,0.05) !important;
				color: $grey !important;
			}
		}
	}

	&.btn_white {
		background-color: $white;
		color: $blue;
		border:1px solid $blue;
		padding-top:4px;
		padding-bottom: 4px;

		&:focus,
		&:hover {
			background-color: $blue;
			color: $white;
		}

		&:disabled {
			color: $grey !important;
			cursor: default !important;
			background-color: rgba($black,0.05) !important;

			&:hover,
			&:focus {
				background-color: rgba($black,0.05) !important;
				color: $grey !important;
			}
		}
	}

	&.btn_grey {

	}

	&.btn_blue_link {
		color: $blue;

		&:focus,
		&:hover {
			color: $blue;
			.btn_text {
				text-decoration: underline;
			}
		}

		&:disabled {
			color: $grey !important;
			cursor: default !important;
			background-color: rgba($black,0.05) !important;

			&:hover,
			&:focus {
				background-color: rgba($black,0.05) !important;
				color: $grey !important;
			}
		}
	}

	&.btn_purple_link {
		color: $purple;

		&:focus,
		&:hover {
			color: $purple;
			.btn_text {
				text-decoration: underline;
			}
		}

		&:disabled {
			color: $grey !important;
			cursor: default !important;
			background-color: rgba($black,0.05) !important;

			&:hover,
			&:focus {
				background-color: rgba($black,0.05) !important;
				color: $grey !important;
			}
		}
	}


	&.btn_no_border {
		border:0 solid !important;
		padding-top:5px;
		padding-bottom: 5px;
	}

	&.btn_no_padding {
		padding: 4px 0px;

		&.btn_no_border {
			padding: 5px 0;

			&.btn_table {
				padding:0 0;
				vertical-align: text-top;
			}
		}
	}

	&.btn_icon_only {
		>.btn_text {
			position: absolute;
			font-size: 0;
		}

		>.btn_icon {
			display: block;

			span {
				display: block;
				font-size: 20px;
				line-height: 20px;
				width: 20px;
				text-align: center;
			}
		}

		&.btn_icon_small {
			padding-left: 5px;
			padding-right: 5px;
			>.btn_icon {
				display: block;

				span {
					display: block;
					font-size: 16px;
					line-height: 20px;
					width: 20px;
					text-align: center;
				}
			}
		}
	}

	&.btn_big {
		padding: 10px;
	}

	&.btn_border {
		border-width: 1px;
		border-style: solid;
		padding:4px 9px;

		&:hover {

		};
	}

	&.btn_view_toggle {
		padding: 10px 10px;
		background-color: $white;
		@include border-radius(0);
		@include border-top-radius(4px);

		&.view_active {
			background-color: $toolbar_grey_light;
			color: $app_color;
		}
	}

	&.btn_wide {
		min-width: 180px;
	}

	&.btn_full {
		display: block;
		width: 100%;
	}

	> .btn_text {
		line-height: 20px;
		display: inline-block;

		&.hidden_text {
			position: absolute;
			font-size:0 !important;
		}
	}

	> .btn_icon,
	> .btn_status {
		position: relative;
		pointer-events: none;
		display: inline-block;
		vertical-align: text-top;
		color: inherit; 
		line-height: 20px;

		span {
			font-size: 16px;
			display: block;
		}

		&.big_icon {
			span {
				font-size: 20px;
			}
		}
	}

	>.btn_status {
		&.status_invalid {
			color: $red;
		}
	}

	> .btn_helper {
		float: right;
		margin-left: 5px;
		line-height: 20px;

		span {
			// font-size: 10px;
			display: block;
			line-height: 20px;
		}
	}

	> .btn_icon_arrow {
		float: right;
		margin-left: 5px;
		line-height: 20px;

		span {
			font-size: 16px;
			display: block;
			line-height: 20px;
		}
	}
}



button::-moz-focus-inner {
    padding: 0;
    border: 0
}



// .btn_group {

// 	.btn_group_left {
// 		float: left;
// 		padding: 4px;
// 		border-right: 0px solid !important;
// 		@include border-radius(4px 0 0 4px);

// 		&.btn_no_border {
// 			padding: 5px;
// 		}

// 		&.btn_grey {
// 			padding: 4px;
// 			border:1px solid $light_grey !important;

// 			&:focus,
// 			&:hover {
// 				border:1px solid $dark_grey !important;
// 			}

// 			&:disabled {
// 				color: $grey !important;
// 				cursor: default !important;
// 				background-color: $lighter_grey !important;
// 				border-color: $lighter_grey !important;

// 				&:hover,
// 				&:focus {
// 					border-color: $lighter_grey !important;
// 					background-color: $lighter_grey !important;
// 					color: $grey !important;
// 				}
// 			}
// 		}
// 	}

// 	.btn_group_right {
// 		float: left;
// 		padding: 4px;
// 		@include border-radius(0 4px 4px 0);

// 		&.btn_no_border {
// 			padding: 5px;
// 		}

// 		&.btn_grey {
// 			padding: 4px;
// 			border:1px solid $light_grey !important;

// 			&:focus,
// 			&:hover {
// 				border:1px solid $dark_grey !important;
// 			}

// 			&:disabled {
// 				color: $grey !important;
// 				cursor: default !important;
// 				background-color: $lighter_grey !important;
// 				border-color: $lighter_grey !important;

// 				&:hover,
// 				&:focus {
// 					border-color: $lighter_grey !important;
// 					background-color: $lighter_grey !important;
// 					color: $grey !important;
// 				}
// 			}

// 		}
// 	}
// }

