
.margin_r-5  {margin-right: 5px;}
.margin_r-10 {margin-right: 10px;}
.margin_r-15 {margin-right: 15px;}
.margin_r-20 {margin-right: 20px;}
.margin_r-25 {margin-right: 25px;}
.margin_r-30 {margin-right: 30px;}

.margin_l-5  {margin-left: 5px;}
.margin_l-10 {margin-left: 10px;}
.margin_l-15 {margin-left: 15px;}
.margin_l-20 {margin-left: 20px;}
.margin_l-25 {margin-left: 25px;}
.margin_l-30 {margin-left: 30px;}

.margin_t-5  {margin-top: 5px;}
.margin_t-10 {margin-top: 10px;}
.margin_t-15 {margin-top: 15px;}
.margin_t-20 {margin-top: 20px;}
.margin_t-25 {margin-top: 25px;}
.margin_t-30 {margin-top: 30px;}

.margin_b-5  {margin-bottom: 5px;}
.margin_b-10 {margin-bottom: 10px;}
.margin_b-15 {margin-bottom: 15px;}
.margin_b-20 {margin-bottom: 20px;}
.margin_b-25 {margin-bottom: 25px;}
.margin_b-30 {margin-bottom: 30px;}

.margin_5  {margin: 5px;}
.margin_10 {margin: 10px;}
.margin_15 {margin: 15px;}
.margin_20 {margin: 20px;}
.margin_25 {margin: 25px;}
.margin_30 {margin: 30px;}

.margin_h-5  {margin-right: 5px; margin-left: 5px;}
.margin_h-10 {margin-right: 10px; margin-left: 10px;}
.margin_h-15 {margin-right: 15px; margin-left: 15px;}
.margin_h-20 {margin-right: 20px; margin-left: 20px;}
.margin_h-25 {margin-right: 25px; margin-left: 25px;}
.margin_h-30 {margin-right: 30px; margin-left: 30px;}

.margin_v-5  {margin-top: 5px; margin-bottom: 5px; }
.margin_v-10 {margin-top: 10px; margin-bottom: 10px; }
.margin_v-15 {margin-top: 15px; margin-bottom: 15px; }
.margin_v-20 {margin-top: 20px; margin-bottom: 20px; }
.margin_v-25 {margin-top: 25px; margin-bottom: 25px; }
.margin_v-30 {margin-top: 30px; margin-bottom: 30px; }







.padding_r-5  {padding-right: 5px;}
.padding_r-10 {padding-right: 10px;}
.padding_r-15 {padding-right: 15px;}
.padding_r-20 {padding-right: 20px;}
.padding_r-25 {padding-right: 25px;}
.padding_r-30 {padding-right: 30px;}

.padding_l-5  {padding-left: 5px;}
.padding_l-10 {padding-left: 10px;}
.padding_l-15 {padding-left: 15px;}
.padding_l-20 {padding-left: 20px;}
.padding_l-25 {padding-left: 25px;}
.padding_l-30 {padding-left: 30px;}

.padding_t-5  {padding-top: 5px;}
.padding_t-10 {padding-top: 10px;}
.padding_t-15 {padding-top: 15px;}
.padding_t-20 {padding-top: 20px;}
.padding_t-25 {padding-top: 25px;}
.padding_t-30 {padding-top: 30px;}

.padding_b-5  {padding-bottom: 5px;}
.padding_b-10 {padding-bottom: 10px;}
.padding_b-15 {padding-bottom: 15px;}
.padding_b-20 {padding-bottom: 20px;}
.padding_b-25 {padding-bottom: 25px;}
.padding_b-30 {padding-bottom: 30px;}

.padding_5  {padding: 5px;}
.padding_10 {padding: 10px;}
.padding_15 {padding: 15px;}
.padding_20 {padding: 20px;}
.padding_25 {padding: 25px;}
.padding_30 {padding: 30px;}

.padding_h-5  {padding-right: 5px; padding-left: 5px;}
.padding_h-10 {padding-right: 10px; padding-left: 10px;}
.padding_h-15 {padding-right: 15px; padding-left: 15px;}
.padding_h-20 {padding-right: 20px; padding-left: 20px;}
.padding_h-25 {padding-right: 25px; padding-left: 25px;}
.padding_h-30 {padding-right: 30px; padding-left: 30px;}

.padding_v-5  {padding-top: 5px; padding-bottom: 5px; }
.padding_v-10 {padding-top: 10px; padding-bottom: 10px; }
.padding_v-15 {padding-top: 15px; padding-bottom: 15px; }
.padding_v-20 {padding-top: 20px; padding-bottom: 20px; }
.padding_v-25 {padding-top: 25px; padding-bottom: 25px; }
.padding_v-30 {padding-top: 30px; padding-bottom: 30px; }