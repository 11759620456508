#mro_footer {

}

#mro_footer_inner {
	text-align: center;
	padding:20px 0;

	#footer_legal {
		font-size: 0.8em;
		color: $mid_grey;
		line-height: 140%;
	}

	#footer_logo {
		height: 40px;
		margin-top: 20px;

		img {
			height: 100%;
		}
	}
}
