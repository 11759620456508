.content_client {

	.client_details_header {

		.client_details_inner {
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:nowrap;
		    -webkit-flex-wrap:nowrap;
		    align-items:center;
		    -webkit-align-items:center;
		    width: 100%;
		    min-height:0;

			.client_card {

			}

			.client_links {
				>ul {
					margin:0;
					padding:0;
					list-style: none;
					font-size: 0;

					>li {
						font-size: $font-size;
						display: inline-block;
						
						a {
							display: block;
							line-height: 20px;
							padding:10px;
						}
					}
				}
			}
		}
	}

	.client_details_body {
		margin-bottom: 20px;
		padding-left: 70px;

		fieldset.grouped {
			border-bottom: 1px solid $border_color;

			&:last-of-type {
				border-bottom: 0px solid;
			}

			.data_row {
				.data_label {
					color: $app_color;
					font-weight: 600;
				}

				.data_value {

				}
			}
		}
	}

}



.client_card {
	position: relative;
	margin-bottom: 20px;

	&.small {
		.client_card_icon {
			span {
				line-height: 40px;
				width: 40px;
				font-size: 40px;
			}
		}

		.client_card_name {
			margin-left: 50px;
			font-size: 1.25rem;
			line-height: 20px;
		}

		.client_card_title {
			margin-left: 50px;
		}
	}

	.client_card_icon {
		position: absolute;
		left: 0;
		top: 0;

		span {
			display: block;
			line-height: 60px;
			width: 60px;
			padding:0px;
			font-size: 60px;
			text-align: center;
			@include border-radius(80px);
			background-color: $white;
			color: $mid_grey;
			@include box-sizing(border-box);
		}
	}
	.client_card_name {
		margin-left: 70px;
		display: block;
        line-height:40px;
        font-weight: 600;
        text-align: left;
        color: $app_color;
        font-size: 1.8rem;
	}
	.client_card_title {
		margin-left: 70px;
		display: block;
        line-height:20px;
        font-weight: 400;
        text-align: left;
        color: $mid_grey;
        font-size: $font_size;
	}
}


.stmnt_header {
	background-color: $light_grey; //$toolbar_grey;
	// color: $white;

	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:flex-start;
    -webkit-align-items:flex-start;
    width: 100%;
    min-height:0;

	.stmnt_title {
		padding:10px;
		line-height: 20px;
	}
	.stmnt_date {
		flex-grow: 1;
		text-align: center;
		padding:10px;
		line-height: 20px;
	}
	.stmnt_data {
		margin-left: 10px;
		padding:10px;
		line-height: 20px;

		a.blockchain_explorer_icon {
			// color: $white;
			display: block;
			font-size: 20px;
			text-decoration: none;
			height: 20px;

			span {
				display: block;
			}

			&:hover, &:focus {
				text-decoration: none;
				color: $gradient_purple_1;
			}
		}
	}
}

.stmnt_content {
	@include box-shadow(0 0 5px rgba($black,0.1));

	.view_container {
	}

}

.stmnt_footer {
	padding: 20px 20px 20px;
	background-color: $lighter_grey;

	.stmnt_footer_inner {
		display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:flex-start;
	    -webkit-align-items:flex-start;
	    width: 100%;
	    min-height:0;

		.stmnt_footer_left {

		}
		.stmnt_footer_right {}
	}
}


.well_container {
	// Well Header
	.well_header {
		background-color: $toolbar_grey; //$light_grey;
		color: $white;

		.well_header_inner {
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:nowrap;
		    -webkit-flex-wrap:nowrap;
		    align-items:flex-start;
		    -webkit-align-items:flex-start;
		    width: 100%;
		    min-height:0;

			.well_header_data {
				padding:10px;
				line-height: 20px;
			}

			.well_header_well {
				padding:10px;
				line-height: 20px;
				flex:0 0 200px 
			}
			.well_header_property {
				padding:10px;
				line-height: 20px;
				margin-right: auto;
			}
			.well_header_doi {
				padding:10px;
				line-height: 20px;
			}
			.well_header_docs {
				padding:10px;
				line-height: 20px;
			}

			a:link {
				color: $white !important;

				&:hover, &:visited {
					color: $white !important;
				}
			}

			strong {
				font-weight: 600;
			}
		}
	}

	// Well Content
	.well_content {
		padding:10px;

		.well_sub_total {
			padding-left: 10px;
			padding-right: 10px;

			.data_value {
				font-size: 1.25rem;
				font-weight: 600;
			}
		}
	}
}


.helpdesk {
	position: relative;
	margin-top:10px;

	.helpdesk_icon {
		position: absolute;
		left: 0;
		top: 0;
		font-size: 40px;
		width: 40px;
		margin:0px auto 10px;

		.helpdesk_icon_main {
			z-index: 1;
			position: relative;
		}

		.helpdesk_icon_shadow {
			position: absolute;
			left: -5px;
			bottom: -5px;
			z-index: 0;
			color: $purple_blush;
		}
	}	

	.helpdesk_text {
		margin-left: 60px;
		p {
			line-height: 20px;
			margin:0;
			padding:0;
		}
	}
}




