#widgets_inner {
	background-color: white;
}


.widget_group {
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:stretch; //stretch
    -webkit-align-items:stretch; //stretch
    width: calc(100% + 20px);
    min-height:0;
    padding: 0px 0;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;

    &.fixed_height {
    	height: 220px;
    }

	.widget_container {
		// margin: 10px;
		flex:1 1 100%;
		@include box-sizing(border-box);
		padding: 0px;

		&.one_third {
			flex:0 1 calc(100% / 3);
		}

		&.two_third {
			flex:0 1 calc(calc(100% / 3) * 2);
		}

		&.half {
			flex:0 1 50%;
		}

		&.quarter {
			flex:0 1 25%;
		}

		&.three_quarter {
			flex:0 1 75%;
		}


		.widget_inner {
			margin:0px 10px 20px;
			// border:1px solid $border_color;
			@include box-sizing(border-box);
			position: relative;
			min-height: 160px;

			&.background_purple {
				padding:10px;
				background-color: $purple;
				border:2px solid $purple;
				color: $white;
				@include border-radius(4px);
				align-self: stretch;
				height: calc(100% - 20px);


				a {
					color: $white;
				}

				.widget_content {
					.widget_content_icon {
						.widget_content_icon_main {
							color: $purple_blush;
						}

						.widget_content_icon_shadow {
							color: rgba($white,0.2);
						}
					}
				}
			}

			&.background_grey {
				padding:10px;
				background-color: $extra_light_purple;
				border:2px solid $extra_light_purple;
				@include border-radius(4px);
				align-self: stretch;
				height: calc(100% - 20px);

				a {
					color: $purple;
				}


				ul.data_list {
					margin:0;

					>li {

						.data_list_label {
							color: $app_color;
						}	
						.data_list_value {

							a.data_list_link {
								color: $purple;
								background-color: $white;

								&:hover,&:focus {
									background-color: $purple;
									color: $white;
								}
							}
						}
					}
				}
			}

			&.background_border {
				padding:10px;
				border:2px solid $purple;
				@include border-radius(4px);
				align-self: stretch;
				height: calc(100% - 20px);

				a {
					color: $purple;
				}
			}
		}

		.widget_header {
			display: flex;
		    flex-direction: row;
		    -webkit-flex-direction: row;
		    justify-content: space-between;
		    flex-wrap:nowrap;
		    -webkit-flex-wrap:nowrap;
		    align-items:flex-start;
		    -webkit-align-items:flex-start;
		    width: 100%;
		    min-height:0;
		    padding: 0px 0 20px;


			.widget_title {
				h3 {
					font-size: 1.25rem; //20px
					line-height: 30px;
					font-weight: 900;
				}
			}

			.widget_actions {
				>ul {
					margin:0;
					list-style: none;
					padding:0;
					font-size: 0;

					>li {
						display: inline-block;
						font-size: $font_size;
						margin-left: 10px; 
						line-height: 30px;
					}
				}
			}

			&.small {
		    	padding: 0px 0 10px;

				.widget_title {
					h3 {
						font-size: 0.8rem; //12px
						line-height: 20px;
						font-weight: 600;
						text-transform: uppercase;		
					}
				}
			}
		}

		.widget_content {
			position: relative;

			.widget_content_icon {
				position: absolute;
				right: 0;
				top: 0;
				font-size: 60px;
				width: 60px;
				margin:0px auto 10px;

				&.wide {
					font-size: 70px;
					width: 70px;
				}
				
				.widget_content_icon_main {
					z-index: 1;
					position: relative;
				}

				.widget_content_icon_shadow {
					position: absolute;
					left: -5px;
					bottom: -5px;
					z-index: 0;
					color: $purple_blush;
				}
			}
		}

		.widget_footer {
			margin-top: 10px;
			line-height: 20px;
		}

		

		
	}	
}


.change_value {
	display: block;
	line-height: 20px;

	&.up {

		&:after {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			content: $icon_indicator_up;
			font-family: 'pipelinedl';
			color: $ticker_up;
		}
	}

	&.down {

		&:after {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			content: $icon_indicator_down;
			font-family: 'pipelinedl';
			color: $ticker_down;

		}
	}
}


/* Data List */
ul.data_list {
	margin:0;
	padding:0;
	list-style: none;

	>li {
	    display: flex;
	    flex-direction: row;
	    -webkit-flex-direction: row;
	    justify-content: space-between;
	    flex-wrap:nowrap;
	    -webkit-flex-wrap:nowrap;
	    align-items:flex-start;
	    -webkit-align-items:flex-start;
	    line-height: 20px;

	    &:last-child {
	    	margin-bottom: 0;
	    }


		.data_list_label {
			padding:5px 0;
			@include transition(all .3s ease);
			color: $mid_grey;
		}	
		.data_list_value {
			padding:5px 0;

			@include transition(all .3s ease);

			a.data_list_link {
				display: block;
				color: $blue;
				background-color: $hover;
				text-decoration: none;
				line-height: 20px;
				padding-left: 5px;
				padding-right: 5px;
				@include border-radius(10px);
				min-width: 30px;
				text-align: center;
				@include box-sizing(border-box);

				&:hover,&:focus {
					background-color: $blue;
					color: $white;
				}
			}
		}

		&:hover {
			.data_list_label {
			}

			.data_list_value {
			}
		}
	}
}


/* Active Leases */
.active_leases {
	font-size: 1.4rem;
	line-height: 20px;
	margin:0px 0 10px;
}


/* Markets */
.market_object {
	text-align: center;

	.market_icon {
		position: relative;
		font-size: 40px;
		width: 40px;
		margin:0px auto 10px;

		.market_icon_main {
			z-index: 1;
			position: relative;
		}

		.market_icon_shadow {
			position: absolute;
			left: -5px;
			bottom: -5px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}



	.market_price {
		font-size: 1.65rem;
		font-weight:500;
		line-height: 40px;
	}
	.market_change {
		columns: $mid_grey;
		line-height: 20px;
	}
	.market_title {
		line-height: 20px;
	}
}


/* Summary */
.summary_item {
	margin-bottom: 20px;

	&.compressed {
		margin-bottom: 10px;
	}

	.summary_label {
		line-height: 20px;
	}

	.summary_value {
		line-height: 40px;
		font-size: 1.65rem;
	    font-weight: 500;
	    line-height: 40px;
	}
}




/****************/
/* Bank Widgets */
/****************/


#widgets_bank {
	background-color: $white;
	padding: 20px 0;
}

/* Payment Notifications */


.widget_data_row {
	margin-bottom: 10px;

	&.no_padding {
		margin-bottom: 0;
	}

	.widget_data_value {
		font-size: 1.65rem;
		font-weight: 600;
		line-height: 30px;
	}

	.widget_data_label {
		line-height: 20px;
		padding:5px 0;
	}

	.widget_data_helper {
		font-size: 0.7rem;
		line-height: 20px;
		@include opacity(0.75);
	}
}



/*******************/
/* Payment Widgets */
/*******************/

.summary_panel {
	position: relative;
	padding: 20px;
	@include box-sizing(border-box);
	border:1px solid $app_color;
	@include border-radius(4px);
	background-color: $white;
	flex: 1 1 100%;

	.summary_icon {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 40px;

		.summary_icon_main {
			z-index: 1;
			position: relative;
		}

		.summary_icon_shadow {
			position: absolute;
			left: -5px;
			bottom: -5px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}

	.summary_title {
		margin-right: 60px;
		font-size: 1.25rem;
		line-height: 140%;
	}

	.summary_content {
		margin-top: 10px;
		// margin-right: 60px;
	}

	.summary_text {
		margin-right: 60px;
		color: $mid_grey;
		line-height: 20px;

		p {

		}

		a {
			display: inline-block;
		}
	}
}




.payment_change {
	font-size: 0.9rem;
	display: inline-block;
	line-height: 20px;
	padding:10px 0;
	vertical-align: bottom;
}


.change_value_inverted {
	display: block;
	line-height: 20px;

	&.down {

		&:after {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			content: $icon_indicator_up;
			font-family: 'pipelinedl';
			color: $ticker_up;
		}
	}

	&.up {

		&:after {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			content: $icon_indicator_down;
			font-family: 'pipelinedl';
			color: $ticker_down;

		}
	}
}


/* Payment Bar in Table */

.payment_bar_container {
	width: 140px;
	font-size: 0;

	.payment_bar {
		display: inline-block;
			vertical-align: top;
			width: calc(attr(data-width));

		.payment_confirmed {
			display: inline-block;
			font-size: 0;
			min-height: 20px;
			background-color: $state_confirmed;
			vertical-align: top;
		}

		.payment_pending {
			display: inline-block;
			font-size: 0;
			min-height: 20px;
			background-color: $state_pending;
			vertical-align: top;
		}

		.payment_issued {
			display: inline-block;
			font-size: 0;
			min-height: 20px;
			background-color: $state_issued;
			vertical-align: top;
		}
	}

	.payment_bar_value {
		font-size: 1.0rem;
		position: absolute;
		margin-left: 5px;
		width: 35px;
		display: inline-block;
		line-height: 20px;
	}
}
