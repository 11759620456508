#dashboard_search {
    background-color: $lighter_grey;
}


#dashboard_search_inner {
	padding: 20px 0;

	#search_form {
		position: relative;
		max-width: 720px;
		margin:0 auto;

		#search_form_input {
			position: relative;
			z-index: 2;
		}

		#search_form_results {
			position: absolute;
			left: 0;
			top: calc(100% + 0px);
			right: 0;

		    background: $white;
		    padding: 0px;
		    @include border-radius(4px);
		    box-shadow: 0 1px 4px rgba($black, 0.3), 0 5px 20px rgba($black, 0.1);
		    @include box-sizing(border-box);
		    max-height: calc(50vh + 50px);
		    overflow: auto;
		    z-index: 999;
		    color: $app_color;
		    text-align: left;

		    #search_form_results_inner {
		    	padding:15px;

		    	.search_form_header {
		    		padding-bottom: 15px;
		    	}

		    	.search_form_content {

		    	}
		    }
		}
	}
}



ul#results_list {
	margin:0 -15px;
	padding:0;
	list-style: none;
	max-height: 50vh;
	overflow: auto;

	li {
		a.result_link {
			color: inherit;
			text-decoration: none;
			position: relative;
			display: block;
			padding-left: 15px;
			padding-right: 15px;

			&:hover, &:focus {
				background-color: $hover;
			}

			.result_icon {
				position: absolute;
				left: 15px;
				top: 10px;
				font-size: 30px;
			}

			.result_details {
				margin-left: 40px;
			    display: flex;
			    flex-direction: row;
			    -webkit-flex-direction: row;
			    justify-content: flex-start;
			    flex-wrap:wrap;
			    -webkit-flex-wrap:wrap;
			    align-items:flex-start;
			    -webkit-align-items:flex-start;
			    width: calc(100% - 40px);
			    min-height:0;
			    padding: 10px 0;
			    line-height: 20px;

				.result_name {
					font-weight: 600;
				}
				.result_id {
					color: $blue;
					margin-left: 10px;
					flex: 1 1 auto;
				}

				.result_error {
					color: $red;
					align-self: flex-end;
				}

				.result_address {
					flex: 1 1 100%;
					color: $mid_grey;
				}
			}

		}
	}
}


.theme_bank {
	#dashboard_search {
		background-color: $purple;
		color: $white;

		#dashboard_search_inner {
			#search_form {
				#search_form_input {
					.form_row {
						.form_input {
							input.inputbox.searchbox {
								border-color: $white;
							}
						}
					}
				}
			}
		}
	}
}



.search_actions {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:space-between;
    -webkit-align-items:space-between;
    width: 100%;
    min-height:0;


	.search_actions_left {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;

			>li {
				font-size: $font-size;
				display: inline-block;
				margin-right: 20px;

				&:last-child {
					margin-right:0;
				}
				
				a {
					color: inherit;
					display: block;
					text-decoration: none;
					line-height: 20px;
					padding:10px 0;

					&:hover, &:focus {
						@include opacity(0.75);
					}

					span {
						display: inline-block;
						vertical-align: bottom;
						font-size: 16px;
						margin:2px;
					}
				}
			}
		}
	}
	.search_actions_right {
		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;

			>li {
				font-size: $font-size;
				display: inline-block;
				margin-left: 20px;

				&:last-child {
					margin-left:0;
				}
				
				a {
					color: inherit;
					display: block;
					text-decoration: none;
					line-height: 20px;
					padding:10px 0;

					&:hover, &:focus {
						@include opacity(0.75);
					}

					span {
						display: inline-block;
						vertical-align: bottom;
						font-size: 16px;
						margin:2px;
					}
				}
			}
		}
	}
}