
.content_header {
	display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: space-between;
    flex-wrap:nowrap;
    -webkit-flex-wrap:nowrap;
    align-items:center;
    -webkit-align-items:center;
    width: 100%;
    min-height:0;
    margin-bottom: 10px;


	.content_history {}

	.content_title {
		flex:1 1 auto;

		h1 {
			line-height: 30px;
			padding:5px 0;
			font-weight: 400;
			font-size: 1.5rem;
		}
	}

	.content_timestamp {
		line-height: 20px;
		padding:10px;
	}

	.content_actions {
		padding: 5px 0;

		>ul {
			margin:0;
			padding:0;
			list-style: none;
			font-size: 0;
			
			>li {
				display: inline-block;
				margin-left: 10px;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.item_status {
	width: 10px;
	height: 10px;
	display: block;
	background-color: $grey;
	@include border-radius(20px);
	margin: 5px 0;
	font-size: 0;

	&.live {
		background-color: $green;
	}
}

.payment_status {
	&.error {
		color: $alert_red;
	}
}


.blockchain_explorer {
	display:block;
	overflow: hidden;
	text-overflow:ellipsis;
	max-width: 120px;
}



.content_footer {
	padding: 20px 20px;
	background-color: $lighter_grey;

	.content_footer_inner {
		display: flex;
		flex-direction: row;
		-webkit-flex-direction: row;
		justify-content: space-between;
		flex-wrap:nowrap;
		-webkit-flex-wrap:nowrap;
		align-items:flex-start;
		-webkit-align-items:flex-start;
		width: 100%;
		min-height:0;


		.content_footer_left {
			>ul {
				font-size: 0;
				margin:0;
				padding:0;
				list-style: none;

				>li {
					font-size: $font_size;
					display: inline-block;
					margin-right: 10px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
		.content_footer_right {
			>ul {
				font-size: 0;
				margin:0;
				padding:0;
				list-style: none;

				>li {
					font-size: $font_size;
					display: inline-block;
					margin-left: 10px;
					
					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}
}




/* Info Container */
.info_container {
	position: relative;
	border:1px solid $dark_grey;
	@include box-sizing(border-box);
	padding:10px;
	@include border-radius(4px);
	margin-top: 20px;

	.info_container_icon {
		position: absolute;
		right: 10px;
		top: 10px;
		padding:0px;
		font-size: 40px;

		.info_container_icon_main {
			margin-left: 5px;
			z-index: 1;
			position: relative;
		}

		.info_container_icon_shadow {
			position: absolute;
			left:0px;
			top:5px;
			z-index: 0;
			color: rgba($app_color,0.1);
		}
	}

	.info_container_details {

	}
}

.info_item {

	.info_label {
		line-height: 20px;
	}

	.info_value {
		line-height: 40px;
		font-size: 1.25rem;
	    font-weight: 500;
	    line-height: 30px;
	    padding: 5px 0 0;
	}
}






.link_options {

	&.has_padding {
		padding: 20px 0;
	}

	&.pos_left {
		text-align: left;

		>ul {
			font-size: 0;
			margin:0;
			padding:0;
			list-style: none;

			>li {
				font-size: $font_size;
				display: inline-block;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	&.pos_right {
		text-align: right;
		
		>ul {
			font-size: 0;
			margin:0;
			padding:0;
			list-style: none;

			>li {
				font-size: $font_size;
				display: inline-block;
				margin-left: 10px;
				
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}



.color_block {
	height: 16px;
	width: 16px;
	display: inline-block;
	vertical-align: text-top;
	background-color: $grey;
	@include border-radius(2px);

	&.color_confirmed {
		background-color: $state_confirmed;
	}

	&.color_pending {
		background-color: $state_pending;
	}

	&.color_issued {
		background-color: $state_issued;
	}
}